<template>
  <div class="main">
    <div class="title-admin">
      <h3>Логи</h3>
      <p>Найдено {{ getLogs.length }} записей</p>
      <a :href="`${$store.getters.getUrlApi}api/admin/event-log/excel`" class="btn-blue">
        Скачать Excel
      </a>
    </div>

    <table>
      <tr>
        <td>Id</td>
        <td>Дата</td>
        <td>Время</td>
        <td>Тип события</td>
        <td>Типо объекта</td>
        <td>Название объекта</td>
        <td>Id объекта</td>
        <td>Имя пользователя</td>
        <td>Id пользователя</td>
        <td>Рабочая станция</td>
        <td>Дата время создания</td>
        <td>Дата время изменения</td>
        <td class="drop-icon"></td>
      </tr>
      <tr v-for="item in getLogs" :key="`itemBlock${item.id}`">
        <td>{{ item.id }}</td>
        <td>{{ item.date }}</td>
        <td>{{ item.time }}</td>
        <td>{{ item.event_type }}</td>
        <td>{{ item.object_type }}</td>
        <td>{{ item.object_name }}</td>
        <td>{{ item.object_id }}</td>
        <td>{{ item.user_name }}</td>
        <td>{{ item.user_id }}</td>
        <td>{{ item.ip }}</td>
        <td>{{ item.created_at }}</td>
        <td>{{ item.updated_at }}</td>
        <td class="drop-icon">
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Log',

  created () {
    this.$store.dispatch('setLogs')
  },

  computed: {
    getLogs () {
      return this.$store.getters.getLogs
    }
  }
}
</script>

<style scoped lang="scss">

.title-admin {
  display: flex;
  align-items: baseline;
  h3 {
    font-weight: 600;
    font-size: 1.37rem;
    color: #1F3245;
  }
  p {
    margin-left: 1.56rem;
    font-size: 0.875rem;
    color: #9D9D9D;
  }
  a{
    margin-left: 1rem;
  }
}

.main {
  overflow: auto;
  height: 50vh;
}

  table{
    margin-top: 2rem;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100vw;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;

        a {
          color: #246CB7;
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }

      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
</style>
